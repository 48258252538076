.crypto__questions{
    background: var(--color-box-transp); 
    padding: 6rem;
    margin: 0rem 0rem 0rem 0rem;
}

.crypto__questions-title{
    
    font-family: var(--font-title); 
    color: var(--color-orange);
    text-align: center;
    font-size: 20px;
}


.crypto__questions-container{
    display: flex;
    flex-direction: row;

    margin: 3rem 0rem 0rem 0rem;
}

.crypto__questions-container-left,
.crypto__questions-container-right{
    width: 50%;
}

@media screen and (max-width: 1300px) {
    
}

@media screen and (max-width: 1050px) {
    
}

@media screen and (max-width: 650px) {

}

@media screen and (max-width: 550px) {
    .crypto__questions{
        padding: 4rem 2rem 0rem 2rem !important;
    }
    .crypto__questions-container{
        flex-direction: column;
    }

    .crypto__questions-container-left,
    .crypto__questions-container-right{
        width: 100%;
    }
}
.crypto__header {
    display: flex;
    flex-direction: row; 
    height: 100vh;
    
    
    background-image: var(--background-crypto);
    background-size: 100%;
    background-position-y: 150px;
    
    background-repeat: no-repeat;
    
}

.crypto__header-content {    
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
        
    color: #fff;
    font-family: var(--font-title);            
}

.crypto__header-content__title_sub{   
    font-size: 20px; 
}
.crypto__header-content__title_highlight{
    color: var(--color-white);
    font-size: 19.60px; 
}
.crypto__header-content__title{
    width: 50%;
    text-align: justify; 
}

.crypto__header-content__input {
    
    margin: 2rem 0rem 0rem 0rem ;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
}



.crypto__header-content__input .dash__button {
    
    padding: 0.2rem 2rem 0.2rem 2rem;
    
    width: 100%;
    
    background: var(--color-orange);
    border: 2px solid var(--color-orange);
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.crypto__header-content__input .dash__button:hover{
    background: var(--color-orangedeep);
    border: 2px solid var(--color-orangedeep);
}
.crypto__header-content__people {    
    display: flex;
    
    flex-direction: row;
    
}



.crypto__header-image {    
    display: flex;
    justify-content: center;
    align-items: center;
}

.crypto__header-image img {
    width: 90%;
    height: 90%;
}

@media screen and (max-width: 1300px) {
    .crypto__header-content__title{
        width: 60%;
    }
}

@media screen and (max-width: 1050px) {
    .crypto__header-content__title{
        width: 70%;
    }

    .crypto__header {
        flex-direction: column;
    }

    .crypto__header-content {
        margin: 0 0 0rem;
    }
}

@media screen and (max-width: 650px) {

    .crypto__header-image img {
        width: 80%;
        height: 80%;
    }
    

    .crypto__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .crypto__header-content__people {
        flex-direction: column;
    }

    .crypto__header-content__people p {
        margin: 0;
    }

    
    .crypto__header-content__input input,
    .crypto__header-content__input .dash__button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .crypto__header{
        background-size: 200%;
        background-position: center;
    }
    .crypto__header-image img {
        width: 85%;
        height: 85%;
    }

    .crypto__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .crypto__header-content__title{        
        width: 70%;
        text-align: justify; 
    }

    .crypto__header-content__title_sub{   
        font-size: 15px; 
    }


    .crypto__header-content__title_highlight{        
        font-size: 16px; 
    }

    .crypto__header-content__input input,
    .crypto__header-content__input .dash__button {
        font-size: 16px;
        line-height: 20px;
    }
}
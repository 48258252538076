.crypto__singlemarket{
    display: flex;
    flex-direction: column;
    background-color: var(--color-box);
    margin: 1rem;
}
.crypto__singlemarket_info{
    display: flex;
    flex-direction: row;
    padding: 2rem 1.5rem 1rem 1.5rem;
    max-height: 125px;
}

.crypto__singlemarket_info-row_right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    margin: 0rem 0.8rem 0rem 0rem;
}
.crypto__singlemarket_info-row_right img{
    height: 30%;
}

.crypto__singlemarket_info-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
}

.crypto__singlemarket_info-text p{
    font-family: var(--font-family);    
    font-size: 18px;
    line-height: 26px;

    color: white;
}

.crypto__singlemarket_info-text .name{
    font-weight: bold;
}


.crypto__singlemarket_info-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    
}

.crypto__singlemarket_info-icon img{
    height: 70%;
}

.crypto__singlemarket_value{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.crypto__singlemarket_value p{
    font-family: var(--font-family);    
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: white;
}

.crypto__singlemarket_percentage{
    display: flex;
    justify-content: right;
    align-items: baseline;
    padding: 2rem;

    font-family: var(--font-family);    
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    color: white;
}



.crypto__singlemarket_percentage img{
    vertical-align: bottom;
    height: 100%;
}
.crypto__singlemarket_percentage p{
    margin: 0rem 0rem 0rem 0.5rem;
}

.crypto__singlemarket_percentage_positive{
    color: var(--color-okdeep);
}
.crypto__singlemarket_percentage_negative{
    color: var(--color-nodeep);
}
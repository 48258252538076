@font-face {
    font-family: 'din_alternatebold';
    src: url('./fonts/din_alternate_bold-webfont.woff2') format('woff2'),
         url('./fonts/din_alternate_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'din_blackregular';
    src: url('./fonts/din_black-webfont.woff2') format('woff2'),
         url('./fonts/din_black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'dinbold';
    src: url('./fonts/din_bold-webfont.woff2') format('woff2'),
         url('./fonts/din_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'dinregular';
    src: url('./fonts/din-webfont.woff2') format('woff2'),
         url('./fonts/din-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

:root {
    --font-family: 'dinregular';
    --font-title: 'din_alternatebold';
    --font-bold: 'dinbold';
    --font-black: 'din_blackregular';
    --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
    --gradient-bar: linear-gradient(0deg, #EE4D19  30%, 60%, #EE6B19 100%);
    
    /*--color-bg: #040C18;
    --color-footer : #031B34;EE4D19
    */
    --color-box: rgb(38, 38, 38);    
    --color-box-transp: rgba(38, 38, 38,0.2);   

    --color-bg: black;
    --color-footer : black;
    --color-blog: #042c54;
    --color-text: white;
    --color-gray: rgb(99, 99, 99);
    --color-lightgray: rgb(192, 192, 192);
    --color-subtext: #FF8A71;
    --color-orange: #EE6B19;
    --color-orange-transp: rgba(238, 107, 25,0.5);
    --color-orangelight: #e9803f;
    --color-orangedeep:#7F3811;
    --color-orangedeep-transp:rgba(127, 56, 17,0.7);
    --color-bgorangedeep: rgba(255, 119, 45, .25);    
    --color-whatsapp: linear-gradient(70deg, #268437, #33db53);
    --color-phone: linear-gradient(70deg, #104273, #225f9c);
    --color-mail: linear-gradient(70deg, #862323, #cc3e3e);
    --color-whatsapp-hover: linear-gradient(70deg, #33db53, #268437);
    --color-phone-hover: linear-gradient(70deg, #225f9c, #104273);
    --color-mail-hover: linear-gradient(70deg, #cc3e3e, #862323);
    --color-ok: #217a16;
    --color-okdeep: #30ac1f;
    --color-nodeep: #FF4A50;
    --color-no: #FF1118;
    --dg-orangedeep:rgba(127,56,17,.2); 
    --background-principal : url('./assets/fondo.png');
    --background-crypto : url('./assets/nodos_bg.png');

    --color-boxop: rgb(38, 38, 38,.6); 
  }
.anubis__testimonys-container{    
    display: flex;
    flex-direction: row;
    max-width: 350px;     
    margin: 0rem 1rem 1rem 0rem;  
    background-color: var(--color-box);    
}



.anubis__testimonys-container__testimony {
    width: 100%;
    max-width: 250px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem;
    padding: 1rem 1.75rem 2rem 1.75rem;
}

.anubis__testimonys-container__testimony-image{
    display: flex;
    flex:1;    
    justify-content: center;
    width: 100%;
}
.anubis__testimonys-container__testimony-image img{
    height: 2.2rem;
    margin-bottom: 1rem;
}


/* .anubis__testimonys-container__testimony-title {
    
    max-width: 200px;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.anubis__testimonys-container__testimony-title h1 {
    font-family: var(--font-bold);
    font-weight: 800;
    font-size: 18px;    
    letter-spacing: 0.04em;
    color: var(--color-text);
    margin-bottom: 1rem;
} */



.anubis__testimonys-container_testimony-text {
    flex: 2;
    max-width: 400px;
    display: flex;
    text-align: center;
    text-justify: inter-word;
    margin-bottom: 2rem;
}

.anubis__testimonys-container_testimony-text p {
    font-family: var(--font-family);
    font-weight: 599;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text);
}

.anubis__testimonys-container__testimony-data{
    display: flex;   
    flex-direction: column;
    flex:3; 
    justify-content: center;
    text-align: center;
    
    width: 100%;
    margin-bottom: -7.5rem;
}
.anubis__testimonys-container__testimony-data img{
    width: 3.5rem;
    border-radius: 50%;
}

.anubis__testimonys-container__testimony-data-text .names{
    font-family: var(--font-bold);
    font-weight: 800;
    font-size: 18px;    
    letter-spacing: 0.04em;
    color: var(--color-text);    
}

.anubis__testimonys-container__testimony-data-text .service_contract{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;    
    letter-spacing: 0.03em;
    color: var(--color-text);    
}

@media screen and (max-width: 550px) {
    .anubis__testimonys-container{            
        margin: 0rem 0rem 6rem 0rem;          
    }
    
    .anubis__testimonys-container__testimony-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .anubis__testimonys-container_testimony-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .anubis__testimonys-container__testimony {
        margin: 1rem 0;
        max-width: 300px;
    }
}
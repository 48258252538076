.anubis__soon {
    display: flex;
    flex-direction: column;    
    padding: 2rem;
    margin-bottom: 10rem !important;    
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));    
}

.anubis__soong-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; 
    justify-content: center;   

    margin-top: 2rem;
}

.anubis__soon-container-image{
    width: 50%;    
    display: flex;    
    justify-content: center;
}

.anubis__soon-container-image img{
    max-height: 250px;
}

.anubis__soon-container-text{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: var(--color-text);
    
    display:flex;
    flex-direction: column;
    vertical-align: middle;
    width: 50%;
    justify-content: center;

   
}

.anubis__soon-container-text ul {
    padding-left: 10px;
    list-style-type: none;
}

.anubis__soon-list > li:before {
    content: "–"; /* en dash here */
    position: absolute;
    color:var(--color-orange);
    margin-left: -1.1em; 
}

/*Esto fue una prueba*/
/* .anubis__soon-container-p .box_soon{
    width: 15px;
    height: 3px;
    background-color: var(--color-orange);
}

.anubis__soon-container-p > * {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
    color: #fff;
    
} */






@media screen and (max-width: 750px) {
    .anubis__soong-container{
        flex-direction: column;
    }

    .anubis__soon-container-image{
        width: 100%;
    }

    .anubis__soon-container-text{
        justify-content: center;
        margin-top: 1rem;
        width: 100%;
    }
    
    .anubis__soon-container-p .box_soon{
        margin: 0rem;
    }

    .anubis__soon-container-text{
        padding-left: 1rem;
        font-size: 16px;
    }
}
.logo {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 900;
    margin: 3rem;
    justify-content: center;

}

.logo img {
    width: 14%;
}

img,
svg {
    vertical-align: middle;
}

.popup{
    width: 800px;
    height: 420px;
    background-color: #262626;
    border-radius: 6px;
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    text-align: center;
    padding: 0 30px 30px;
    color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    visibility: hidden;
    transition: transfrom 0.04s, top 0.04s;
}

.popup img{
    width: 150px;
    margin-top: -50px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.popup h2{
    font-family: var(--font-bold);
    font-size: 35px;
    line-height: 40px;
    color: rgb(236, 99, 42);
}

.popup p{
    padding-top: 20px;
    font-size: 25px;
    font-family: var(--font-family);
    justify-content: center;
}

.button-popup{
    cursor: pointer;
    width: 35%;
    background-color: rgb(236, 99, 42);
    border-color: rgb(236, 99, 42);
    border-radius: 15px;
    padding: 0.2rem 0;
    font-size: 25px;
    font-family: var(--font-bold);
    color: white;    
}
.button-popup:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    color: #262626;
}

.open-popup {
    visibility: visible;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
}

.crypto__listservices{
    display: flex;
    flex-direction: column; 

    padding: 10rem 6rem 2rem 4rem;
}

.crypto__listservices-title{
    color: var(--color-orange);
    font-family: var(--font-title);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;
}

.crypto__listservices-list{
    display: flex;
    flex-direction: row;

    padding: 2rem 0rem 0rem 2rem;

    
    border-bottom: 3px solid var(--color-orangedeep);

    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
}

.crypto__listservices-list div{
    margin: 0rem 0rem 0rem 0rem;
}

.crypto__listservices-list a{
    padding: 0rem 1rem 0rem 1rem;
    cursor: pointer;
    border-bottom: 3px solid var(--color-orangedeep);
}

.crypto__listservices-list a:hover{
    border-bottom-color: var(--color-orange);
}

.select{
    border-bottom-color: var(--color-orange) !important;
}

.hide{
    display: none;
}

.crypto__listservices-info{    
    background-color: var(--color-boxop);
    margin: 2rem 1rem 5rem 1rem;
}

.crypto__listservices-info-title{
    padding: 3rem 0rem 2rem 0rem;
    margin: 0rem 3rem 0rem 3rem;

    border-bottom: 3px solid var(--color-orangedeep);

    text-align: center;

    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
}

.crypto__listservices-info-video{

    text-align: center;
}

.crypto__listservices-info-video img{
    width: 1000px;
}

.crypto__listservices-info__padding{
    padding: 0rem 4rem 0rem 4rem;
}

.crypto__listservices-info__margin{
    margin: 3rem 0rem 5rem 0rem;
}

.crypto__listservices-info-boxes{
    display: flex;
    flex-direction: column;
    
}

.crypto__listservices-info-calculator{
    display: flex;
    flex-direction: column;

    text-align: center;    
    align-items: center;
    /* background-color: var(--color-orange); */
    background: var(--gradient-bar);
}

.crypto__listservices-info-calculator-title{

    margin: 3rem 0rem 0rem 0rem;


    color: var(--color-box);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 30px;
    line-height: 25px;
    -webkit-text-stroke-width: 0.05px;
    -webkit-text-stroke-color: black
}

.crypto__listservices-info-calculator-text{
    padding: 0rem 6rem 0rem 6rem;
    margin: 2rem 0rem 0rem 0rem;

    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
}

.crypto__listservices-info-calculator-link {
    display: flex;

    margin: 2rem 0rem 2rem 0rem;
    padding: 0.8rem 0rem 0.8rem 0rem;
    border-radius: 50px;
    width: 22%;

    text-align: center;
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;

    background-color: var(--color-box);
}

.crypto__listservices-info-calculator-link:hover{
    background-color: var(--color-orange);
}

.crypto__listservices-info-calculator-link a{
    width: 100%;
    
}

.crypto__listservices-calculator{
    margin: 3rem 4rem 2rem 4rem;
    padding: 3rem 0rem 5rem 0rem;    
    border-radius: 25px;
    background: var(--gradient-bar);

}
.crypto__listservices-calculator-title{ 
    padding-left: 3rem;
    margin-bottom:1rem ;

    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 35px;
    line-height: 25px;
}


.crypto__listservices-calculator-container{
    display: flex;
    flex-direction: row;
}

.crypto__listservices-calculator-container-left,
.crypto__listservices-calculator-container-right{
    width: 50%;
}

.crypto__listservices-calculator-container-right{
    margin: 3rem 0rem 0rem 0rem;
    
}
.crypto__listservices-calculator-form{
    
    padding: 3rem 6rem 3rem 3rem;

    background-color: var(--color-box);
    margin: 2rem 0rem 2rem 0rem;


    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 100;
    font-size: 20px;
    line-height: 25px;

    box-shadow: 0px 7px 15px 1px rgba(0, 0, 0, 1);
}

.crypto__listservices-calculator-form-input{
    margin: 1em 0rem 1rem 0rem;
}

.crypto__listservices-calculator-form-input sup{
    align-items: top;
    text-align: center;
    font-size: 12px;
    color: var(--color-orange);
}
.crypto__listservices-calculator-form-input div{
    margin: 0.2rem 0rem 0rem 0rem;
    border-bottom: 2px solid var(--color-text);

    color: var(--color-lightgray);
    font-size: 18px;
}

.crypto__listservices-calculator-form-input div:hover{
    border-bottom: 2px solid var(--color-lightgray);
}

.crypto__listservices-calculator-form-input input{
    width: 95%;
    background-color: transparent;    

    color: var(--color-lightgray);
    font-family: var(--font-family);
    font-weight: 100;
    font-size: 18px;
    line-height: 25px;

    border:none;
}

.crypto__listservices-calculator-form-input #range{
    width: 100%;
}
.crypto__listservices-calculator-form-input .crypto__months{
    font-size: 14px;   
    color: var(--color-lightgray);     
}

.crypto__listservices-calculator-form-input input:focus{
    outline: none;
}

#range {
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    
    -webkit-transition: .9s;
    transition: opacity .9s;
}

#range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%; 
    background: var(--color-orange);
    cursor: pointer;
}

#range::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--color-orange);
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
.crypto__listservices-calculator-form-input input::-webkit-outer-spin-button,
.crypto__listservices-calculator-form-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.crypto__listservices-calculator-form-input input[type=number] {
  -moz-appearance: textfield;
}


.crypto__listservices-calculator-info{
    margin: 0rem 0rem 0rem 6rem;
    color: var(--color-orange);
    font-family: var(--font-family);
    font-weight: 1000;
    font-size: 14px;
    line-height: 25px;
}

.crypto__listservices-link{
    margin: 4rem 0rem 0rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.crypto__listservices-link-container{
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    
}

.crypto__listservices-link h1{
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 1000;
    font-size: 45px;
    line-height: 25px;
}

.crypto__listservices-link-button{
    display: flex;

    margin: 3rem 1rem -1rem 0rem;
    padding: 1rem;
    border-radius: 50px;

    text-align: center;
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 30px;
    line-height: 25px;

    background-color: var(--color-orange);
}

.crypto__listservices-link-button:hover{
    background-color: var(--color-orangedeep);
}
.crypto__listservices-link-button a{
    width: 100%;    
}




/* Movil section */

@media screen and (max-width: 1300px) {
    
}

@media screen and (max-width: 1050px) {
    .crypto__listservices-calculator-form{    
        padding: 3rem;
    }

    .crypto__listservices-info-calculator-link{
        width: 50%;
    }

    .crypto__listservices-info-calculator-text{
        padding: 0rem 4rem 0rem 4rem;
    }
    
    .crypto__listservices-calculator-title{
        line-height: 40px;
    }

    .crypto__listservices-calculator-form-input input{
        width: 90%;
    }

    .crypto__listservices-link-button{
        width: 35%;
        margin: 1rem 0rem -1rem 0rem;
    }

    .crypto__listservices-link h1{
        font-size: 35px;
        line-height: 40px;
    }
}

@media screen and (max-width: 650px) {
    .crypto__listservices-link-container{
        flex-direction: column;
    }

    .crypto__listservices-link-button{
        width: 100% !important;
    }
}

@media screen and (max-width: 550px) {
    .crypto__listservices{
        padding: 4rem 1rem 0rem 1rem;    
    }

    .crypto__listservices-list{
        padding: 2rem 0rem 0rem 0rem;
        text-align: center;
        border:none;
    }
    
    .crypto__listservices-list a{
        padding: 0rem 0.5rem 0rem 0.5rem;
        
    }

    .crypto__listservices-info{
        margin: 2rem 0rem 4rem 0rem;
    }
    
    .crypto__listservices-info-title{
       line-height: 45px; 
       margin: 0rem 2rem 0rem 2rem;
    }

    .crypto__listservices-info-video{
        padding: 0rem 0rem 0rem 0rem;
        margin: 3rem 0rem 2rem 0rem;
    }

    .crypto__listservices-info-video img{
        width: 300px;
        
    }

    .crypto__listservices-info-boxes{
        padding: 0rem 0rem 0rem 0rem;
        margin: 0rem 2rem 2rem 2rem;
    }
    
    .crypto__listservices-info-calculator-title{
        line-height: 35px;
    }

    .crypto__listservices-info-calculator-text{
        padding: 0rem 1.5rem 0rem 1.5rem;
    }
    
    .crypto__listservices-info-calculator-link{
        align-items: center;
        width: 70%;
    }

    .crypto__listservices-calculator{
        margin: 3rem 2rem 3rem 2rem
    }

    .crypto__listservices-calculator-title{
        padding: 0rem 2rem 0rem 2rem;
        font-size: 22px;
        line-height: 25px;
    }

    .crypto__listservices-calculator-container{
        flex-direction: column;
    }

    .crypto__listservices-calculator-container-left, .crypto__listservices-calculator-container-right{
        width: 100%;
    }
    
    .crypto__listservices-calculator-form{
        padding: 3rem 2rem 3rem 2rem;
    }

    .crypto__listservices-calculator-form-input input{
        width: 90%;
    }

    .crypto__listservices-calculator-container-right{
        margin: 0rem 0rem 0rem 0rem;
    }
    
    .crypto__listservices-calculator-info{
        margin: 0rem 2rem 0rem 2.5rem;
    }

    .crypto__listservices-link{
        text-align: center;
    }

    .crypto__listservices-link h1{
        font-size: 34px;
        line-height: 28px;
    }

    .crypto__listservices-link-button{
        margin: 2rem 0rem -1rem 0rem;
        width: 60%;
    }
}
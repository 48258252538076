.anubis__analysis {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);    
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.anubis__analysisg-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;    
    margin-top: 2rem;
    justify-content: center;
}

.anubis__analysis-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem 1rem 1rem 1rem;
}
.anubis__services {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);    
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.anubis__servicesg-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;    
    margin-top: 2rem;
    justify-content: center;
}
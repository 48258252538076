.help__container{
    background: var(--color-footer);

    padding: 6rem 6rem 8rem 6rem;

    display: flex;    
    
}

.box_orange{
    width: 45px;
    height: 2px;
    background: var(--color-orange);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    
    margin: 2rem 2rem 0rem 0rem;

    
}

.help__text{
    display: flex;
    flex-direction: row;
    width: 40%;

    margin: 2rem;

    font-family: var(--font-family);
    color:white;    
    font-size: 16px;
    line-height: 20px;
}

.help__text-title{
    display: flex;
}

.help__text-container .help__text-container-title{
    color: var(--color-orange);
    font-size: 40px;
    line-height: 40px;
}

.help__text-container .help__text-container-sub{
    margin: 1rem 0rem 0rem 0rem;
    font-size: 25px;
    line-height: 30px;
}


.help__form {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem 2rem 4rem;
    
    width: 60%;
}

.help__name-mail{
    display: flex;
}

.help__name-mail .help__name,
.help__name-mail .help__mail{
    width: 50%;
}

.help__name,
.help__mail{
    font-family: var(--font-family);
    color:white;    
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 0rem;
    font-weight: 100;
}

.help__name{
    margin: 0rem 3rem 0rem 0rem;
}
.help__form input{
    display: flex;
    
    background: var(--color-footer) I !important;
    width: 100%;
    padding: 0.5rem 0.5rem 0.2rem 0.5rem;
    margin: 0.5rem 0rem 0.5rem 0rem;
    
    font-family: var(--font-family);
    color:white;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    outline: none !important;
    border-bottom: 3px solid white;
    border-right: 0px solid white;
    border-left: 0px solid white;
    border-top: 0px solid white;
}

::placeholder {    
    color:var(--color-lightgray);    
}



.help__form button {
    
    margin: 1rem 0rem 0rem 0rem;
    padding: 0.2rem 1rem 0.2rem 1.4rem;
    
    width: 20%;
    
    background: var(--color-orange);
    border: 2px solid var(--color-orange);
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    

    font-family: var(--font-bold);
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    
}

.buttonSubmit:hover{
    background: var(--color-orangedeep);
    border: 2px solid var(--color-orangedeep);
}

.errorSubmit{
    background: var(--color-orangedeep) !important;
    border: 2px solid var(--color-orangedeep) !important;
    color:var(--color-lightgray) !important;  
}
.errorSubmit:hover{
    background: var(--color-orange) !important;
    border: 2px solid var(--color-orange) !important;
    color: #fff;
}

.okSubmit{
    background: var(--color-ok) !important;
    border: 2px solid var(--color-ok) !important;
    color:var(--color-lightgray) !important;  
    cursor: default !important;
}

/* Loader */
.divLoader{
    display: flex;
    justify-content: center;
    
}
.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid var(--color-box); /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}
  
.arrow_right{
    font-size: 30px;
}

.div__button{    
    display: flex;
    
    align-items: center;    
    justify-content: center;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media screen and (max-width: 550px) {
    
    .help__container{
        display: flex;
        flex-direction: column;
        
        
        padding: 4rem 2rem 6rem 2rem !important;
    }
    
    .help__text{
        width: 100%;
        margin: 0rem;
    }
    

    .help__text-container .help__text-container-title{
        
        font-size: 30px;
        
    }
    
    .help__text-container .help__text-container-sub{
        
        font-size: 20px;
        line-height: 22px;
        
    }

    .help__form{     
        display: flex;
        flex-direction: column;  
        padding: 0rem 1rem 0rem 1rem;
        margin: 3rem 0rem 0rem 0rem;

        width: 100%;
    }

    .help__name-mail{
        display: flex;
        flex-direction: column;
    }

    .help__name-mail .help__name,
    .help__name-mail .help__mail{
        width: 100%;
        margin: 0rem 0rem 0rem 0rem;
    }

    .help__name p,
    .help__mail p{
        display: none;
    }

    .help__form input{
        width: 100%;        
    }

    .help__form button{
        width: 100%;
    }



}
.anubis__whatanubis {
    display: flex;
    flex-direction: column;

    padding: 5rem 2rem 2rem 2rem;
    background: var(--color-footer);

    /* background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%); */

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.anubis__whatanubis-feature {
    display: flex;
}


.anubis__whatanubis-feature .anubis__features-container__feature {
    margin: 0;
}


.anubis__whatanubis-feature .anubis__features-container_feature-text {
    max-width: 700px;
}

.anubis__whatanubis-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.anubis__whatanubis-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.anubis__whatanubis-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.anubis__whatanubis-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;    
    margin-top: 2rem;    
    justify-content: center;
}

.anubis__whatanubis-container .anubis__whatanubis-container-design{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0rem 2rem 0rem 2rem;
}

.anubis__whatanubis-container-design .anubis__whatanubis-container-image,
.anubis__whatanubis-container-design .anubis__whatanubis-graphs{
    width: 50%;
    display: flex;    
    justify-content: center;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0rem;
}
.anubis__whatanubis-container-design .anubis__whatanubis-graphs{
    flex-direction: column;
    max-width: 350px;
}

.anubis__whatanubis-graphs-crypto,
.anubis__whatanubis-graphs-analysis{
    max-height: 250px;
    
    background: var(--color-box);
    padding: 1.5rem;
    margin: 0rem 0rem 0.5rem 0rem;

    display: flex;
    flex-direction: column;

    font-family: var(--font-bold);
    color: var(--color-orange);
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    
}

.anubis__whatanubis-graphs-crypto .anubis__whatanubis-graphs-cryptoimages,
.anubis__whatanubis-graphs-analysis .anubis__whatanubis-graphs-analysisimages{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem 0rem 0rem 0rem;
}

.anubis__whatanubis-graphs-cryptosnow,
.anubis__whatanubis-graphs-cryptotrading,
.anubis__whatanubis-graphs-analysisgw,
.anubis__whatanubis-graphs-analysisnode,
.anubis__whatanubis-graphs-analysis .anubis__whatanubis-graphs-analysis-subtitle{
    margin: 0rem 0.5rem 0rem 0.5rem;
    font-family: var(--font-family);
    font-weight: 500;
    text-align: center;
    font-size: 18px;
    line-height: 15px;
    color: white;
}
.anubis__whatanubis-graphs-analysis-subtitle{
    margin-top: 1rem !important;
}
.anubis__whatanubis-graphs-cryptosnow img{
    width: 115px !important;        
    height: 115px !important;
    margin: 0rem 0rem 0.8rem 0rem;
}

.anubis__whatanubis-graphs-crypto img,
.anubis__whatanubis-graphs-analysis img
{
    width: 125px;        
    height: 125px;
}

.anubis__whatanubis-container-image img{
    height: 500px;
}

.anubis__whatanubis-container .anubis__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}


.anubis__whatanubis-container .anubis__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .anubis__whatanubis-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .anubis__whatanubis-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
   
    .anubis__whatanubis-feature .anubis__features-container__feature {
        flex-direction: column;
    }

    
    .anubis__whatanubis-feature .anubis__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .anubis__whatanubis-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .anubis__whatanubis-container .anubis__whatanubis-container-design{
        flex-direction: column;
    }

    .anubis__whatanubis-container-design .anubis__whatanubis-container-image,
    .anubis__whatanubis-container-design .anubis__whatanubis-graphs{
        width: 100%;
        margin: 1rem 0rem 0rem 0rem;
    }

    .anubis__whatanubis-container-image img{
        height: 300px;
    }

    .anubis__whatanubis-graphs-crypto img,
    .anubis__whatanubis-graphs-analysis img
    {
        width: 100px;        
        height: 100px;
    }

    .anubis__whatanubis-graphs-cryptosnow img{
        width: 90px !important;        
        height: 90px !important;
        
    }

    .anubis__whatanubis-graphs-crypto, .anubis__whatanubis-graphs-analysis{
        margin: 0rem 0rem 1rem 0rem;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .anubis__whatanubis-container .anubis__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }

}
.anubis__contactinfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
}

.anubis__contactinfo .info_button {
    
    max-height: 60px;
    min-width:40px;
    margin: 1rem 1rem 1rem 2rem;
    padding: 0.7em;
    background-color: var(--color-orange);
    border-radius: 100px;
   
}

.info_button img{
    height: 35px;
    width: 35px;
}

.anubis__contactinfo .info_button:hover{
    background-color: var(--color-orangedeep);
}

.anubis__contactinfo .contact_button {
    margin: 0rem 2rem 0rem 0rem;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;  
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;
    line-height: 45px;
    letter-spacing: 0.03em;
    color:white;
    background-color: var(--color-orange);
    border-radius: 100px;
   
}

@media screen and (max-width: 550px) {
    .anubis__contactinfo .contact_button {
        margin: 0rem ;               
    }  
    .anubis__contactinfo .info_button {
            
        margin: 1rem 0.5rem 0rem 0rem;
        
       
    }
}
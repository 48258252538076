.anubis__section-container__feature {    
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    margin: 0rem 1rem 1rem 1rem;
}

.anubis__section-container__feature-title {
    flex: 1;
    max-width: 400px;
    margin-right: 2rem;
}

.anubis__section-container__feature-title h1 {
    margin-left: 5px;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 35px;
    line-height: 45px;
    letter-spacing: 0.03em;
    color: #fff;
}

.anubis__section-container__feature-title div {
    width: 45px;
    height: 2px;
    background: var(--color-orange);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.anubis__section-container__feature-title > * {
    display: inline-block;
    vertical-align: middle;

    padding-left: 10px;
    color: #fff;
    
}

.anubis__section-container_feature-text {
    flex: 2;
    max-width: 590px;
    display: flex;
}

.anubis__section-container_feature-text p {
    font-family: var(--font-bold);
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--color-orange);
    text-transform: uppercase;
}

@media screen and (max-width: 550px) {
    .anubis__section-container__feature-title h1 {
        font-size: 25px;
        line-height: 35px;
    }

    .anubis__section-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .anubis__section-container__feature {
        margin: 1rem 0;
    }
}
.anubis__form {
    display: flex;
    flex-direction: column;
    padding: 0rem 2rem 0rem 2rem;       
}


.anubis__form input{
    display: flex;
    background: var(--color-box);
    border-radius: 15px;
    width: 100%;
    padding: 1rem;
    margin: 0rem 0rem 0.5rem 0rem;
    
    font-family: var(--font-family);
    color:var(--color-gray);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    outline: none !important;
    border: 0px solid white;
          
}

.anubis__form .textArea{
    height: 120px;
}

::placeholder {    
    color:var(--color-lightgray);    
}



input:hover,
input:focus,
input:valid {
    opacity: 0.9;
    background: white;    
}

.anubis__form button {
    margin: 0rem 0rem 0rem 0rem;
    padding: 0.2rem 0rem 0.2rem 0rem;
    
    width: 100%;
    
    background: var(--color-orange);
    border: 2px solid var(--color-orange);
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    
}

.buttonSubmit:hover{
    background: var(--color-orangedeep);
    border: 2px solid var(--color-orangedeep);
}

.errorSubmit{
    background: var(--color-orangedeep) !important;
    border: 2px solid var(--color-orangedeep) !important;
    color:var(--color-lightgray) !important;  
}
.errorSubmit:hover{
    background: var(--color-orange) !important;
    border: 2px solid var(--color-orange) !important;
    color: #fff;
}

.okSubmit{
    background: var(--color-ok) !important;
    border: 2px solid var(--color-ok) !important;
    color:var(--color-lightgray) !important;  
    cursor: default !important;
}

/* Loader */
.divLoader{
    display: flex;
    justify-content: center;
    
}
.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid var(--color-box); /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media screen and (max-width: 550px) {
    .anubis__form{     
        display: flex;  
        padding: 0rem;
        margin: 1rem 0rem 0rem 0rem;
        
        
    }

    .anubis__form input{
        width: 100%;
        
    }

    
}
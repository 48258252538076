.help__header {
    display: flex;
    flex-direction: row; 
    height: 100vh;
    
    
    background-image: var(--background-crypto);
    background-size: 100%;
    background-position-y: 150px;
    
    background-repeat: no-repeat;
    
}

.help__header-content {
    margin: 4rem 0rem 0rem 0rem;    
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-direction: column;
        
    color: #fff;
    font-family: var(--font-title);            
}

.help__header-content__title{
    width: 60%;
    text-align: center; 
}

.help__header-content__title_highlight{
    color: var(--color-orange);
    font-size: 40px; 
}

.help__header-content__title_sub{ 
    margin: 3rem 0rem 0rem 0rem;  
    font-size: 15px; 
}

.help__header-content__input {
    
    margin: 2rem 0rem 0rem 0rem ;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
}



.help__header-content__input .dash__button {
    
    padding: 0.5rem 2rem 0.5rem 2rem;
    
    width: 100%;
    
    
    background: var(--color-orange-transp);
    border: 2px solid var(--color-orange-transp);
    
    
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    

    
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 25px;
    line-height: 28px;
}



.help__header-content__input .dash__button:hover{
    background: var(--color-orange);
    border: 2px solid var(--color-orange);
    opacity: 1;
}

.help__header-content__people {    
    display: flex;
    
    flex-direction: row;
    
}



.help__header-image {    
    display: flex;
    justify-content: center;
    align-items: center;
}

.help__header-image img {
    width: 90%;
    height: 90%;
}

@media screen and (max-width: 1300px) {
    .help__header-content__title{
        width: 60%;
    }
}

@media screen and (max-width: 1050px) {
    .help__header-content__title{
        width: 70%;
    }

    .help__header {
        flex-direction: column;
    }

    .help__header-content {
        margin: 0 0 0rem;
    }
}

@media screen and (max-width: 650px) {

    .help__header-image img {
        width: 80%;
        height: 80%;
    }
    

    .help__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .help__header-content__people {
        flex-direction: column;
    }

    .help__header-content__people p {
        margin: 0;
    }

    
    .help__header-content__input input,
    .help__header-content__input .dash__button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .help__header{
        background-size: 200%;
        background-position: center;
    }
    .help__header-image img {
        width: 85%;
        height: 85%;
    }

    .help__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .help__header-content__title{        
        width: 70%;
        text-align: center; 
    }

    .help__header-content__title_sub{   
        font-size: 14px; 
    }


    .help__header-content__title_highlight{        
        font-size: 30px; 
    }

    .help__header-content__input input,
    .help__header-content__input .dash__button {
        font-size: 16px;
        line-height: 20px;
    }
}
.input-anubis{
    font-family: var(--font-family);
    background-color: #262626;
    color:black;
    padding: 0.7rem;
    border-radius: 15px;
    border:transparent;
    font-size: 18px;
    font-weight: 400;
    margin: 0 1.5rem 0.5rem 0;
    width: 100%;
}

.form-anubis{
    margin-left: 25px;
    margin-right: 25px;
    gap:0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
}

.form-anubis div{
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: 0.8rem 0.3rem 0 0.3rem;       
}  

.button-anubis{
    cursor: pointer;
    background-color: rgb(236, 99, 42);
    border-color: rgb(236, 99, 42);
    border-radius: 15px;
    padding: 0.2rem;
    font-size: 24px;
    color: white;
    font-family: var(--font-bold);
    width: 30%;
    height: 50px;
    
}
.button-anubis:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    color: black;
}

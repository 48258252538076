.crypto__markets {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 2rem 2rem;
}

.react-multi-carousel-list{
    position: static;
}


.react-multiple-carousel__arrow{
     background: rgba(0,0,0,0.0);
}
.crypto__benefits{
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem 2rem 2rem;
}

.crypto__benefits-container{
    display: flex;
    flex-direction: row;    
    justify-content: right;
    margin: 2rem 0rem 3rem 1rem;

    max-height: 250px;
    
    background-color: var(--color-orange);

    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;

}

.crypto__benefits-container_img{
    display: flex;
    flex-direction: column;        
    align-items: flex-end;
    
    width: 50%;
    margin: 3rem 0rem 0rem 0rem;    
}

.crypto__benefits-container_img img{
    width: 65%;
}

.crypto__benefits-container_text{
    display: flex;
    flex-direction: row;
    
    margin: 0rem 0rem 0rem 0rem;
    padding: 3rem;
    width: 50%;

    font-family: var(--font-family);
    font-weight: 800;
    font-size: 35px;
    line-height: 45px;
    letter-spacing: 0.03em;
    color: #fff;
}

.crypto__benefits-container_text p{
    width: 75%;
}

.crypto__benefits-container_img-mobile{
    display: none;
}

@media screen and (max-width: 1350px) {
    .crypto__benefits-container_text{
        width: 60%;
    }    
}   

@media screen and (max-width: 450px) {
    .crypto__benefits{
        padding: 2rem 2rem 2rem 2rem;
    }
    .crypto__benefits-container{
        flex-direction: column;
        border-radius: 50px;
        

        margin: 2rem 1rem 3rem 0rem;
    }

    .crypto__benefits-container_text{
        text-align: center;
        width: 100%;
        font-size: 25px;
        line-height: 30px;
        padding: 2rem 1rem 1rem 1rem;
    }   
    
    .crypto__benefits-container_text p{
        width: 100%;
    }

    .crypto__benefits-container_img{
        display:none;
    }
    .crypto__benefits-container_img-mobile{

        display: flex;
        flex-direction: column;        
        align-items: center;                
        margin: 1rem 0rem 0rem 0rem;   
    }

    .crypto__benefits-container_img-mobile img{
        width: 80%;
    }
}   
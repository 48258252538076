.anubis__services-container{    
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    display: flex;
    flex-direction: row;
    max-width: 500px;     
    margin: 0rem 1rem 0rem 0rem;  
    background-color: var(--color-box);    
}

.anubis__services-container:hover{    
    box-shadow: 0px 0px 0px 3px var(--color-orange) inset;
}



.anubis__services-container__service {
    width: 100%;
    max-width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem;
    padding: 2rem;
}

.anubis__services-container__service-image img{
    height: 5rem;
    margin-bottom: 1rem;
}


.anubis__services-container__service-title {
    /* flex: 1; */
    max-width: 200px;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.anubis__services-container__service-title h1 {
    font-family: var(--font-bold);
    font-weight: 800;
    font-size: 18px;    
    letter-spacing: 0.04em;
    color: var(--color-text);
    margin-bottom: 1rem;
}

.anubis__services-container__service-title .box_service{
    background: var(--color-orange);
    
    width: 60px;
    height: 3px;
}

.anubis__services-container_service-text {
    flex: 2;
    max-width: 400px;
    display: flex;
    text-align: justify;
    text-justify: inter-word;
}

.anubis__services-container_service-text p {
    font-family: var(--font-family);
    font-weight: 599;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text);
}

@media screen and (max-width: 1060px) {
    .anubis__services-container{            
        margin: 0rem 1rem 1rem 0rem;          
    }
    
}   


@media screen and (max-width: 550px) {
    .anubis__services-container{            
        margin: 0rem 0rem 1rem 0rem;          
    }
    
    .anubis__services-container__service-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .anubis__services-container_service-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .anubis__services-container__service {
        margin: 1rem 0;
    }
}
.crypto__infobox{
    display: flex;
    flex-direction: row;

    margin: 2rem 0rem 0rem 0rem;
}

.crypto__infobox-img{
    background-color: var(--color-box);
    padding: 3rem;
    width: 30%;
    box-shadow: 10px 10px 15px 1px rgba(0, 0, 0, 1);
}
.crypto__infobox-text{
    display: flex;
    flex-direction: column;
        
    justify-content: center;
    text-align: justify;
    text-justify: inter-word;


    font-family: var(--font-family);
    font-weight: 599;
    font-size: 20px;
    line-height: 25px;
    color: var(--color-text);


    
    width: 70%;
}


.crypto__infobox-margin-left{
    margin: 0rem 4rem 0rem 0rem;
}

.crypto__infobox-margin-right{
    margin: 0rem 0rem 0rem 4rem;
}


@media screen and (max-width: 1300px) {
    
}

@media screen and (max-width: 1050px) {

    .crypto__infobox{
        flex-direction: column;
        align-items: center;
    }
    
    .crypto__infobox-img{
        
        width: 50%;
        padding: 3rem;
    }

    .crypto__infobox-text{
        margin: 2rem 0rem 0rem 0rem;
        width: 90%;

    }
    
}

@media screen and (max-width: 650px) {
    
}

@media screen and (max-width: 490px) {
    .crypto__infobox{
        flex-direction: column;
        align-items: center;
    }
    
    .crypto__infobox-img{
        padding: 2rem;
        width: 100%;
    }

    .crypto__infobox-text{
        margin: 2rem 0rem 0rem 0rem;
        width: 90%;

    }
}
.anubis__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--gradient-bar);
}

.anubis_footer-contact-info{
    display:flex;
    flex-direction: row;
    background: var(--color-box);
    padding: 1rem 2rem 1rem 2rem;
    margin-top: -3rem;
    
    box-shadow: 2px 2px 30px black;
}

.anubis_footer-contact-info_title{
    margin-top: auto;
    margin-bottom: auto;
}
.anubis_footer-contact-info p{
    font-family: var(--font-title);
    font-size: 28px;    
    color: #fff;

    margin-bottom: 0rem;

}

.anubis_footer-contact-info_phone .info_button {
    padding: 0.6rem 0.6rem 0.6rem 0.6rem;
    background-color: var(--color-orange);
    border-radius: 100px;
   
}

.info_button img{
    height: 30px;
    width: 30px;
}

.whatsapp_button{
    background: var(--color-whatsapp) !important;
}

.whatsapp_button:hover{
    background: var(--color-whatsapp-hover) !important;
}

.phone_button{
    background: var(--color-phone) !important;
}

.phone_button:hover{
    background: var(--color-phone-hover) !important;
}

.mail_button{
    background: var(--color-mail) !important;
}

.mail_button:hover{
    background: var(--color-mail-hover) !important;
}

.anubis_footer-contact-info_phone .info_button:hover{
    background-color: var(--color-orangedeep);
}

.anubis_footer-contact-info_phone {
    margin: 0rem 0rem 0rem 4rem;
}

.anubis_footer-contact-info_phone  > * {
    display: inline-block;
    vertical-align: middle;

    margin: 0rem 0rem 0rem 1rem;
}

.anubis_footer-contact-info_phone .subtitle_phone{
    font-family: var(--font-family);
    font-size: 14px;    
    color: #fff;
}

.anubis__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.anubis__footer-links div {
    width: 250px;
    margin: 1rem;
}

.anubis__footer-links_logo {
    display: flex;
    flex-direction: column;
}



.anubis__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    
}




.anubis__footer-logo_img > * {
    max-width: 50%;
    display: inline-block;
    vertical-align: middle;    
    color: var(--color-box);
    font-family: var(--font-title);
    font-weight: 500;
    font-size: 30px;
    line-height: 20px;
    text-transform: capitalize;
}
.anubis__footer-logo_img img {
    width: 60px;
    height: 60px;    
}

.anubis__footer-social-network{
    display: flex;
    margin: 0rem 0rem 2rem 1rem !important  ;
}

.anubis__footer-social-network a{
    display: flex;
    max-width: 30px;
    max-height: 30px;
}
.anubis__footer-social-network div{
    padding: 0.4rem 0.5rem 0.4rem 0.5rem;
    margin: 0.4rem 0.4rem 0.4rem 0rem;

    display:flex;
    max-width: 30px;
    max-height: 40px;
    border-radius: 100%;
    background-color: var(--color-box);    
    justify-content: center;    
    vertical-align: auto;
}

.anubis__footer-social-network div:hover{
    
    background-color: var(--color-orange);    
    
}
.anubis__footer-social-network img{
    width: 15px;
    height: 15px;    
}

.anubis__footer-links_div {
    display: flex;    
    width: 300px !important;  
    margin: auto 1rem 2.5rem 1rem !important;
    
    
}

.anubis__footer-links_div div{    
    margin: 0rem 3rem 0rem 0rem;
    text-align: end;
}
.anubis__footer-links_div p {
    font-size: 14px;
    line-height: 18px;
    font-family: var(--font-bold);
    color: var(--color-box);
    font-weight: 500;

    margin-bottom: 0.9rem;
}





@media screen and (max-width: 850px) {
    .anubis__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .anubis__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .anubis__footer-links_div  {
        margin: 3rem 1rem 1rem 1rem !important;
        
    }

    .anubis__footer-links_div div  {
        
        text-align: start;
    }

    .anubis__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }

    
    .anubis__footer-social-network{        
        margin: 0rem 0rem 0rem 1rem !important;
    }
    .anubis__footer-social-network div{
        padding: 0.4rem 0.5rem 0.4rem 0.5rem;
        margin: 0.4rem 0.4rem 0.4rem 0rem;
    }

    .anubis_footer-contact-info{                
        margin: -6rem 0rem 0rem 0rem;
        padding: 1rem 1.5rem 1rem 1.5rem;
        flex-direction: column;
        text-align: center;
        max-width: 275px;

    }
    
    .anubis_footer-contact-info_phone{
        margin: 1rem 0rem 0rem 0rem;
        width: 100%;
    }
    .anubis_footer-contact-info_phone .info_button{
        margin: 0rem 0rem 0rem 0.5rem;
        
    }
    
    .info_button img{
        height: 40px;
        width: 40px;
    }
    
    .anubis_footer-contact-info_title{                
        
        margin: 0rem 0rem 0rem 1rem;           
    }
    .anubis_footer-contact-info_phone .info_text{                
        margin: 1rem 0rem 0rem 0rem;           
    }
}

@media screen and (max-width: 400px) {
    .anubis__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}

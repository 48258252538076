* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

html, body{
    margin: 0;        
}
body {
    background-color: var( --color-bg );
}

.PrincipalApp{    
    background-image: var( --background-principal );
}

a {
    color: unset;
    text-decoration: none;

}

.gradient__bg {
    background: var( --color-bg );
} 

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 0rem 6rem 0rem 6rem;
}

.section__margin {
    margin: 3rem 6rem 3rem 6rem;
}

.section__margin_left {
    margin: 3rem 0rem 3rem 6rem;
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
}

@keyframes scale-up-center {
0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
}
100% {
    -webkit-transform: scale(1);
            transform: scale(1);
}
}

@media screen and (max-width: 700px) {
.section__padding {
    padding: 4rem;
}

.section__margin {
    margin: 4rem;
}
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem 0rem 2rem;
    }

    .section__margin {
        margin: 4rem 1rem 4rem 1rem;
    }  

    .section__margin_left{
        margin: 4rem 0rem 4rem 1rem;
    }
}
.crypto__service-container{    
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    
    display: flex;
    flex-direction: row;         
    padding: 2rem;
    margin: 0rem 0rem 2rem 0rem;    
    background-color: var(--color-box);    

    width: 100%;
}

.crypto__service-container__service-image{
    display:flex;
    width: 18%;
    justify-content: center;
    align-items: center;
    margin: 0rem 2rem 0rem 0rem
}

.crypto__service-container__service-image img{
    height: 80%;
    width: 90%;    
}

.crypto__service-container__service-info{
    display: flex;
    flex-direction: column;

    justify-content: center;    
    width: 82%;    

}
.crypto__service-container__service-title {        
    margin: 0rem 0rem 1rem 0rem;
    
}

.crypto__service-container__service-title h1 {
    font-family: var(--font-bold);
    font-weight: 800;
    font-size: 24px;    
    letter-spacing: 0.04em;
    color: var(--color-orange);    
}

.crypto__service-container_service-text {        
    display: flex;
    text-align: justify;
    text-justify: inter-word;
    margin: 0rem 1.5rem 0rem 0rem;
}

.crypto__service-container_service-text p {
    font-family: var(--font-family);
    font-weight: 599;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text);
}

.crypto__service-container_service-button {    
    margin: 2rem 1rem 1rem 0rem ;    
    text-align: right;
    justify-content: right;
    align-items: right;
}

.crypto__service-container_service-button .learn-more__button{
    padding: 0.2rem 1rem 0.2rem 1rem;        
    background: var(--color-orange);
    border: 2px solid var(--color-orange);
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    
    font-family: var(--font-bold);    
    font-size: 16px;
    line-height: 28px;
}

.crypto__service-container_service-button .learn-more__button:hover{
    background: var(--color-orangedeep);
    border: 2px solid var(--color-orangedeep);
}

.learn-more__button-inside{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.learn-more__button-inside img{
    width: 10px;
    margin: 0rem 0rem 0rem 0.5rem;
}


@media screen and (max-width: 1060px) {
    .crypto__service-container{            
        margin: 0rem 1rem 1rem 0rem;          
    }
    
}   


@media screen and (max-width: 550px) {
    .crypto__service-container{            
        flex-direction: column;
        margin: 0rem 0rem 1rem 0rem;
                  
    }
    .crypto__service-container__service-info{
        width: 100%;
    }
    .crypto__service-container__service-image{
        width: 100%;
        margin: 0rem 0rem 0rem 0rem;
    }
    .crypto__service-container__service-image img{
        width: 60%;
    }

    .crypto__service-container__service-title{
        text-align: center;
        margin: 1rem 0rem 0rem 0rem;
    }

    .crypto__service-container__service-title h1 {
        font-size: 20px;
        line-height: 22px;
    }

    .crypto__service-container_service-text{
        margin: 1rem 0rem 0rem 0rem;
    }
    .crypto__service-container_service-text p {
        font-size: 12px;
        line-height: 20px;
    }    

    .crypto__service-container_service-button{
        text-align: center;
        width: 100%;
        margin: 2rem 0rem 1rem 0rem;
    }

    .crypto__service-container_service-button .learn-more__button{
        width: 100%;
    }
    
    
}
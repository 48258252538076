.crypto__services {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 2rem 2rem;        
}

.crypto__servicesg-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;    
    margin: 2rem 0rem 0rem 1rem;
    justify-content: center;
}

@media screen and (max-width: 550px){
    .crypto__servicesg-container{
        margin: 2rem 0rem 0rem 0rem;
    }
}
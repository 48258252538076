.crypto__calculator {
    padding: 0rem 6rem 0rem 3rem;
    margin: 1.5rem 0rem 0rem 0rem;
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 100;
    font-size: 20px;
    line-height: 25px;
}

.crypto__calculator-final{
    margin: 3.5rem 0rem 0rem 0rem;
    padding: 2rem 6rem 3rem 3rem;

    background: var(--color-box);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 100;
    font-size: 20px;
    line-height: 25px;

    box-shadow: 0px 7px 15px 1px rgba(0, 0, 0, 1);
}

.crypto__calculator sup{
    font-size: 12px;
    color: var(--color-bg);
}

.crypto__calculator-container{
    border-bottom: 2px solid var(--color-text);
}
.crypto__calculator-container span{
    width: 50%;
    display: inline-block;
    font-weight: 1000;
    font-size: 16px;
}

.crypto__calculator-container-percentage{
    text-align: end;
}

.green_value{
    color: var(--color-okdeep);
}

.red_value{
    color: var(--color-no);
}


@media screen and (max-width: 1300px) {
    
}

@media screen and (max-width: 1050px) {
    .crypto__calculator {
        padding: 0rem 3rem 0rem 3rem;
    }


    .crypto__calculator-final{        
        padding: 2rem 2rem 3rem 3rem;
    }

    
}

@media screen and (max-width: 650px) {
    
}

@media screen and (max-width: 490px) {
    .crypto__calculator{
        padding: 0rem 2.5rem 0rem 2.5rem;
    }
    .crypto__calculator-final{
        padding: 2.5rem 2.5rem 2.5rem 2.5rem;
    }
}


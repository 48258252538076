.question__box{    
    background: white;
    
    margin: 1rem;
    font-family: var(--font-title); 
    color: var(--color-orange);    
    font-size: 16px;
}

.question__icon{
    margin: 0rem 1rem 0rem 0rem ;
    height: 100%;
    width: 5%;

    transition: 0.4s;
} 

.question__title{
    display: flex;
    flex-direction: row;
    vertical-align: center;
    align-items: center;

    padding: 1rem;
    font-size: 20px;

    
    height: 80px;

    /*Unselectable*/
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;

}

.question__title:hover{
    background: var(--color-lightgray);
}

.question__title p{
    width: 90%;
}

.question__text{    
    color: var(--color-footer);
    
    
    text-align: justify;    
    
    height: auto;
    opacity: 1;
    
    width: 100%;

    /* transition: height 0ms 1000ms, opacity 400ms 0ms; */
    transition: 0.5s;
}

.question__text strong{
    font-family: var(--font-bold);
}

.question__text a{
    font-family: var(--font-title);
    color: var(--color-orange);

}
.question__text-pd{
    padding: 1rem 1.4rem 2rem 1.4rem;
}

.hide__question{
    overflow: hidden;  /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    transition: 0.5s;
}



@media screen and (max-width: 1300px) {
    
}

@media screen and (max-width: 1050px) {
    
}

@media screen and (max-width: 650px) {

}

@media screen and (max-width: 550px) {
    .question__title{
        height: 110px;
    }

    .question__box{
        margin: 1rem 0rem 1rem 0rem;
    }
}
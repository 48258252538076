.anubis__header {
    display: flex;
    flex-direction: row; 
    height: 100vh;
}

.anubis__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}


.anubis__header-content h1 {
    padding-top: 12px;
    color: #fff;
    font-family: var(--font-title);    
    font-size: 68px;
    line-height: 70px;
    letter-spacing: 0.35em;
    text-align: center;
    margin-left: 0.6rem;
    
}



.anubis__header-content__input {
    
    margin: 1rem 1rem 1rem 0rem ;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
}



.anubis__header-content__input .analysis__button {
    margin: 0rem 0.5rem 0.5rem 0.5rem;
    padding: 0.2rem 2rem 0.2rem 2rem;
    
    width: 100%;
    
    background: var(--color-orange);
    border: 2px solid var(--color-orange);
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.anubis__header-content__input .analysis__button:hover{
    background: var(--color-orangedeep);
    border: 2px solid var(--color-orangedeep);
}
.anubis__header-content__people {    
    display: flex;
    
    flex-direction: row;
    
}

.anubis__header-content__people h2 {
    
    font-family: var(--font-family);
    letter-spacing: -0.01em;
    font-weight: 500;
    font-size: 20px;    
    color: #fff;
    
}

.anubis__header-image {    
    display: flex;
    justify-content: center;
    align-items: center;
}

.anubis__header-image img {
    width: 90%;
    height: 90%;
}

@media screen and (max-width: 1050px) {
    .anubis__header {
        flex-direction: column;
    }

    .anubis__header-content {
        margin: 0 0 0rem;
    }
}

@media screen and (max-width: 650px) {

    .anubis__header-image img {
        width: 80%;
        height: 80%;
    }
    .anubis__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .anubis__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .anubis__header-content__people {
        flex-direction: column;
    }

    .anubis__header-content__people p {
        margin: 0;
    }

    .anubis__header-content__input{
        margin: 1rem 1rem 6rem 1rem;
    }
    .anubis__header-content__input input,
    .anubis__header-content__input .analysis__button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {

    .anubis__header-image img {
        width: 85%;
        height: 85%;
    }

    .anubis__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .anubis__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .anubis__header-content__input input,
    .anubis__header-content__input .analysis__button {
        font-size: 16px;
        line-height: 20px;
    }
}
.anubis__navbar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1rem 6rem 1rem 6rem;    
    position: absolute;
    width: 100%;  
    top:0px;  
    z-index: 1;
    transition: top 0.5s; 
}

.anubis__navbar a:hover{
    color: var(--color-orange);

}
.hide_navbar{
    top: -100px;
}
.sticky_navbar {
    top: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.993);
    opacity: 0.8;                
}


.anubis__navbar-links {
    flex: 1;
    display: flex;
    justify-content: inherit;
    align-items: center;
}

.anubis__navbar-links_logo {
    margin-right: 2rem;
}
.anubis__navbar-links_logo > * {
    display: inline-block;
    vertical-align: middle;

    padding-left: 10px;
    color: #fff;
    font-family: var(--font-title);
    font-weight: 500;
    font-size: 30px;
    line-height: 25px;
    /* text-transform: capitalize; */
}
.anubis__navbar-links_logo img {
    width: 65.56px;
    height: 65.02px;
}

.anubis__navbar-links_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

.anubis__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.anubis__navbar-links_container p,
.anubis__navbar-sign p,
.anubis__navbar-menu_container p
{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.anubis__navbar-sign button,
.anubis__navbar-menu_container button {
    color: #fff;
    background: transparent;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    
    padding: 0.5rem 1.8rem 0.5rem 1.8rem;
    margin-left: 25px;

    border: 2px;
    outline: solid;
    cursor: pointer;
    border-radius: 5px;
}

.buttonNavbar:hover{
    color: #fff;
    background: var(--color-orange);            
    /* border:2px solid #FF4820; */
    outline: none;
}

.anubis__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.anubis__navbar-menu svg {
    cursor: pointer;
}

.anubis__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);    
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.anubis__navbar-menu_container p {
    margin: 1rem 0;
}

.anubis__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .anubis__navbar-sign {
        display: none;
    }
    .anubis__navbar-links_container {
        display: none;
    }
    .anubis__navbar-menu_container-links-sign {
        display: block;
    }
    .anubis__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .anubis__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .anubis__navbar {
        padding: 1rem 1rem 0.5rem 1rem;
    }

    .anubis__navbar-sign {
        display: none;
    }

    .anubis__navbar-menu_container {
        top: 20px;
    }

    .anubis__navbar-menu_container-links-sign {
        display: block;
    }
    
}
.anubis__features-container{    
    display: flex;
    flex-direction: row;  
    margin-bottom: 1rem;  
}

.anubis__features-container .box {
    width: 3px;
    height: 100%;
    background: var(--color-orange);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    
}

.anubis__features-container__feature {
    width: 100%;
    max-width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem;
}



.anubis__features-container__feature-title {
    flex: 1;
    max-width: 180px;
    margin-right: 2rem;
}

.anubis__features-container__feature-title h1 {
    font-family: var(--font-bold);
    font-weight: 1000;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: var(--color-orange);
    text-transform: uppercase;
}



.anubis__features-container_feature-text {
    flex: 2;
    max-width: 400px;
    display: flex;
    text-align: justify;
    text-justify: inter-word;
}

.anubis__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 599;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text);
}

@media screen and (max-width: 1300px){
    .anubis__features-container{  

        max-width: 320px;
    }

}



@media screen and (max-width: 550px) {
    .anubis__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .anubis__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .anubis__features-container__feature {
        margin: 1rem 0;
    }
}
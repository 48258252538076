.anubis__crypto {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);        
}

.anubis__cryptog-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;    
    margin-top: 2rem;    
    justify-content: center;
}

.anubis__crypto-container {
    width: 100%;
    max-width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem 1rem 1rem 1rem;
    
}


.anubis__crypto-container-title {
    flex: 1;
    max-width: 250px;  
    max-height: 24px;  
    margin: 0rem 0rem 1rem 0rem;
}

.anubis__crypto-container-title h1 {
    font-family: var(--font-bold);
    font-weight: 1000;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: var(--color-orange);
    text-transform: uppercase;
}



.anubis__crypto-container-text {
    flex: 2;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    text-align: justify;
    text-justify: inter-word;
    
}

.anubis__crypto-container-text p {
    font-family: var(--font-family);
    font-weight: 599;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text);

    margin: 0rem 0rem 1rem 0rem;
}

.anubis__crypto-container-text ul{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-text);

    margin: 0rem 0rem 1rem 1rem;
}
.anubis__crypto-container-text button {
    margin: 0rem 0rem 1rem 0rem;
    padding: 0.2rem 0rem 0.2rem 0rem;
        
    
    background: var(--color-orange);
    border: 2px solid var(--color-orange);
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    

    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}

.anubis__crypto-container-text button:hover{
    background: var(--color-orangedeep);
    border: 2px solid var(--color-orangedeep);
}


.anubis__crypto-contact{
    background: var(--color-box);
    width: 40%;
    padding: 2rem;

    display: flex;
    flex-direction: column;
}
.anubis__crypto-contact .anubis__crypto-contact-title{
     font-family: var(--font-title);
    color:#fff;
    font-weight: 400;
    font-size: 15px;
    line-height: 35px;
}
.anubis__crypto-contact .box{
    background: var(--color-orange);
    height: 3px;
    margin: 1rem 0.5rem 0.5rem 0rem;
    width: 50%;
}
.anubis__crypto-contact .anubis__crypto-contact-text{
    margin: 1rem 0rem 0rem 0rem;
    font-family: var(--font-family);
    color:#fff;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
}
.anubis__crypto-contact-form{
    width: 40%;
    
}

@media screen and (max-width: 1300px) {

    .anubis__crypto-container{
        max-width: 300px;
    }
}


@media screen and (max-width: 550px) {
    .anubis__crypto-container-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .anubis__crypto-container-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .anubis__crypto-container {
        margin: 1rem 0;
    }


    .anubis__crypto-container-text button {
        font-size: 15px;
    }


    .anubis__crypto-contact{
        width: 100%;
    }


    .anubis__crypto-contact-form{
        width: 100%;
        
    }
}